// import React from 'react'
import {Link} from 'gatsby'
import styled from 'react-emotion'
import tinycolor from 'tinycolor2'

const Breadcrumb = styled(Link)`
  margin-bottom: 5px;
  color: ${props => props.theme.red};
  text-decoration: none;

  div > a& {
    border-bottom: 0 none;
  }

  &:hover {
    color: ${props =>
      tinycolor(props.theme.red)
        .darken()
        .toHexString()};
  }
`

export default Breadcrumb
