// import React from 'react'
// import {Link} from 'gatsby'
import styled from 'react-emotion'
// import tinycolor from 'tinycolor2'

const Title = styled.h1`
  color: ${props => props.theme.yellow};
  text-shadow: -1px 0 ${props => props.theme.red},
    0 1px ${props => props.theme.red}, 1px 0 ${props => props.theme.red},
    0 -1px ${props => props.theme.red};
  font-size: 30px;
  font-family: ${props => props.theme.headerFont};
`
export default Title
