import styled from 'react-emotion'

const PageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  align-content: center;
  position: relative;
`

export default PageContainer
