import React from 'react'
import Helmet from 'react-helmet'

import SiteContainer from '../components/common/SiteContainer'
import PageContainer from '../components/common/PageContainer'
import Header from '../components/Header'
import PageBody from '../components/common/PageBody'
import Footer from '../components/Footer'
import { ThemeProvider } from 'emotion-theming'
import theme from '../theme'

import '../assets/css/index.css'
import '../assets/css/prism.css'

class TemplateWrapper extends React.Component {

  render() {
    let chapter = true
    const pathRegex = new RegExp(
      '^\\/[\\w\\-\\.]+(\\/)?[\\w\\-\\.]*(\\/)?$',
      'gi'
    )
    const path = pathRegex.exec(this.props.location.pathname)
    if (
      path &&
      (path[2] === '/' ||
        (path[1] === '/' && this.props.location.pathname.substr(-1, 1) !== '/'))
    )
      chapter = false

    return (
      <ThemeProvider theme={theme}>
        <SiteContainer>
          <Helmet
            title="Hitchhiker's Guide to Javascript"
            meta={[
              {
                name: 'description',
                content:
                  'A roaring adventure through the universe of Javascript',
              },
            ]}
          />
          <PageContainer chapter={chapter}>
            <Header />
            <PageBody>{this.props.children}</PageBody>
            <Footer />
          </PageContainer>
        </SiteContainer>
      </ThemeProvider>
    )
  }
}

export default TemplateWrapper
