import React from 'react'
import {Link} from 'gatsby'
import styled from 'react-emotion'
import tinycolor from 'tinycolor2'

import leftArrow from '../../assets/images/icon-left-arrow.svg'
import rightArrow from '../../assets/images/icon-right-arrow.svg'

const PageLinks = styled.ul`
  background-color: ${props => props.theme.mediumPurple};
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style-type: none;
  margin: 0 -${props => props.theme.paddingSize / 2}px;
  position: fixed;
  width: 100vw;
  max-width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  & li {
    min-width: 100%;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
      min-width: 150px;
    }

    & img {
      margin-bottom: 0;
    }

    &:first-child {
      order: 2;
      @media screen and (min-width: 768px) {
        order: 1;
        border-right: 1px solid ${props => props.theme.purple};
      }

      & a {
        padding-left: 0;

        & span {
          margin-right: ${props => props.theme.paddingSize / 2}px;
        }
      }
    }
    &:last-child {
      order: 1;
      border-bottom: 4px solid ${props => props.theme.purple};
      @media screen and (min-width: 768px) {
        order: 2;
        border-bottom: 0 none;
        border-left: 1px solid ${props => props.theme.purple};
      }
      & a {
        justify-content: flex-end;
        padding-right: 0;

        & span {
          margin-left: ${props => props.theme.paddingSize / 2}px;
        }
      }
    }
  }
  & > li > a {
    font-size: 20px;
    text-decoration: none;
    color: ${props => props.theme.yellow};
    font-family: 'PT Sans';
    border-bottom: 0;
    padding-right: ${props => props.theme.paddingSize / 2}px;
    padding-left: ${props => props.theme.paddingSize / 2}px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & span {
      background-color: ${props =>
        tinycolor(props.theme.red)
          .darken()
          .toHexString()};
      padding: ${props => props.theme.paddingSize / 2}px
        ${props => props.theme.paddingSize / 4}px;
    }

    &:hover {
      background-color: ${props => props.theme.purple};

      & span {
        background-color: ${props => props.theme.red};
      }
    }
  }
`

class Links extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bottom: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    const footer = document.getElementsByTagName('footer')
    if (footer) {
      const top = footer[0].offsetTop
      const bottomPos = document.documentElement.scrollTop + window.innerHeight
      let bottom = bottomPos - top
      if (bottom < 0) bottom = 0
      if (this.state.bottom !== bottom) this.setState({ bottom })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const { prevPage, nextPage, prevChapter, nextChapter, currentChapter } = this.props
    return (
      <PageLinks style={{ bottom: this.state.bottom }}>
        <li>
          {prevPage ? (
            <Link to={`/${currentChapter.slug}/${prevPage.path}/`}>
              <span>
                <img src={leftArrow} alt="previous page"/>
              </span>
              {prevPage.title}
            </Link>
          ) : prevChapter ? (
            <Link to={`/${prevChapter.slug}`}>
              <span>
                <img src={leftArrow} alt="previous chapter" />
              </span>
              {prevChapter.title}
            </Link>
          ) : null}
        </li>
        <li>
          {nextPage ? (
            <Link to={`/${currentChapter.slug}/${nextPage.path}/`}>
              {nextPage.title}
              <span>
                <img src={rightArrow} alt="next page"/>
              </span>
            </Link>
          ) : nextChapter ? (
            <Link to={`/${nextChapter.slug}/`}>
              {nextChapter.title}
              <span>
                <img src={rightArrow} alt="next chapter"/>
              </span>
            </Link>
          )
          : null }
        </li>
      </PageLinks>
    )
  }
}

export default Links
