import React, { Fragment } from 'react'
// import {Link} from 'gatsby'
import styled from 'react-emotion'
// import tinycolor from 'tinycolor2'
import { graphql } from "gatsby"

import Title from './common/Title'
import Breadcrumb from './common/Breadcrumb'
import PageLinks from './common/PageLinks'
import PageQuote from './PageQuote'
import BlogLayout from "../layouts/blog";

const BodyText = styled.div`
  @media screen and (min-width: 768px) {
    padding-right: ${props => props.theme.paddingSize}px;
    padding-left: ${props => props.theme.paddingSize}px;
  }
`

const PageComponent = props => {
  const { nextPage, prevPage, nextChapter, prevChapter, page } = props.data;
  return (
    <BlogLayout location={props.location}>
      <Fragment>
        <Breadcrumb to={`/${page.chapter.slug}/`}>
          {props.data.page.chapter.title}
        </Breadcrumb>
        <Title>{page ? page.title : null}</Title>
        {page && page.quote ? <PageQuote quote={page.quote} /> : null}
        <BodyText
          dangerouslySetInnerHTML={{
            __html: page.body.childMarkdownRemark.html,
          }}
        />
        <PageLinks
          prevPage={prevPage}
          prevChapter={prevChapter}
          currentChapter={page.chapter}
          nextPage={nextPage}
          nextChapter={nextChapter}
        />
      </Fragment>
    </BlogLayout>
  )
}

export default PageComponent

export const query = graphql`
  query PageQuery($page: String!, $nextPage: String, $prevPage: String, $nextChapter: String, $prevChapter: String) {
    page: contentfulPage(path: { eq: $page }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
      quote {
        childMarkdownRemark {
          html
        }
      }
      pageNumber
      chapter {
        slug
        title
      }
    }
    nextPage: contentfulPage(path: { eq: $nextPage }) {
      title
      path
    }
    prevPage: contentfulPage(path: { eq: $prevPage }) {
      title
      path
    }
    nextChapter: contentfulChapter(slug: { eq: $nextChapter }) {
      title
      slug
    }
    prevChapter: contentfulChapter(slug: { eq: $prevChapter }) {
      title
      slug
    }
  }
`
