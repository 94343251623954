import React from 'react'
import styled from 'react-emotion'

import quoteMark from '../../assets/images/icon-quote.svg'

const Quote = styled.div`
  background-color: ${props => props.theme.lightGreen};
  color: ${props => props.theme.darkGrey};
  padding: ${props => props.theme.paddingSize}px;
  font-size: 16px;
  line-height: 26px;
  font-family: 'PT Serif Caption';
  font-style: italic;
  font-weight: 600;
  position: relative;
  margin-bottom: ${props => props.theme.paddingSize}px;

  & p:last-child {
    margin-bottom: 0;
  }
`

const QuoteMark = styled.img`
  position: absolute;
  top: -${props => props.theme.paddingSize / 2}px;
  right: -${props => props.theme.paddingSize / 2}px;
`

const PageQuote = ({ quote }) => (
  <Quote>
    <QuoteMark src={quoteMark} />
    <div dangerouslySetInnerHTML={{ __html: quote.childMarkdownRemark.html }} />
  </Quote>
)

export default PageQuote
